import React from "react"
import "./PlayerFourIllustration.scss"

export default function PlayerFourIllustration() {
  return (
    <div className="player-four-illustration">
      <svg
        id="b0613604-cb64-4fd5-a4c3-be829c572cfc"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 822.13 662.91"
      >
        <defs>
          <style></style>
        </defs>
        <title>investor</title>
        <circle className="cls-1" cx="594.13" cy="536.71" r="126.2" />
        <circle className="cls-2" cx="730.37" cy="297.74" r="91.76" />
        <circle className="cls-3" cx="616.14" cy="52.37" r="52.37" />
        <g>
          <path
            className="cls-4"
            d="M584.07,76.07a2.64,2.64,0,0,1-1.08-.25,2.83,2.83,0,0,1-1-.68,1.26,1.26,0,0,1-.14-1.69l6.19-10.66-6-10.58a1.93,1.93,0,0,1-.28-.94,1.29,1.29,0,0,1,.36-.9,2.32,2.32,0,0,1,.92-.61,2.87,2.87,0,0,1,1.09-.22,1.23,1.23,0,0,1,.88.31,2.22,2.22,0,0,1,.56.77l5,9.22,5-9.22a2.63,2.63,0,0,1,.61-.77,1.22,1.22,0,0,1,.83-.31,2.92,2.92,0,0,1,1.1.22,2.51,2.51,0,0,1,.93.61,1.23,1.23,0,0,1,.38.9,1.93,1.93,0,0,1-.28.94l-6,10.58,6.23,10.66a1.58,1.58,0,0,1,.21.75,1.38,1.38,0,0,1-.39.94,3,3,0,0,1-1,.68,2.62,2.62,0,0,1-1.1.25,1.11,1.11,0,0,1-.72-.27,2.13,2.13,0,0,1-.5-.59l-5.29-9.58-5.29,9.58a2.27,2.27,0,0,1-.49.59A1.09,1.09,0,0,1,584.07,76.07Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M608.12,74.31A1.89,1.89,0,0,1,607,74a1,1,0,0,1-.49-.88V68.91h-4.18a1.06,1.06,0,0,1-.88-.45,1.81,1.81,0,0,1-.34-1.13,1.92,1.92,0,0,1,.34-1.16,1,1,0,0,1,.88-.46h4.18V61.57a1.12,1.12,0,0,1,.49-.87,1.74,1.74,0,0,1,1.13-.39,1.65,1.65,0,0,1,1.15.39,1.16,1.16,0,0,1,.43.87v4.14h4.14a1.15,1.15,0,0,1,.9.46,1.72,1.72,0,0,1,.4,1.16,1.63,1.63,0,0,1-.4,1.13,1.16,1.16,0,0,1-.9.45H609.7v4.18a1.07,1.07,0,0,1-.43.88A1.79,1.79,0,0,1,608.12,74.31Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M618.45,76a2.23,2.23,0,0,1-1.1-.35,1,1,0,0,1-.59-.91v-3.5a4.72,4.72,0,0,1,.84-2.71,11.5,11.5,0,0,1,2.2-2.36c.9-.75,1.86-1.47,2.86-2.18s2-1.43,2.85-2.16a12.32,12.32,0,0,0,2.19-2.29,4.28,4.28,0,0,0,.87-2.59,4,4,0,0,0-.42-1.76,3.51,3.51,0,0,0-1.27-1.44,4.4,4.4,0,0,0-4.81.29,3.25,3.25,0,0,0-1.06,2.7,1.3,1.3,0,0,1-.53,1,2.13,2.13,0,0,1-1.45.45,1.74,1.74,0,0,1-1.26-.45A2.33,2.33,0,0,1,617.3,56a5.66,5.66,0,0,1,1-3.36,6.72,6.72,0,0,1,2.7-2.22,8.73,8.73,0,0,1,7.49.11,7.33,7.33,0,0,1,2.82,2.5,6.63,6.63,0,0,1,1.06,3.76,7.12,7.12,0,0,1-.84,3.46,11.48,11.48,0,0,1-2.18,2.81,23.9,23.9,0,0,1-2.83,2.3l-2.81,2a14.39,14.39,0,0,0-2.16,1.87,3,3,0,0,0-.84,2v1.37h10.55a1.09,1.09,0,0,1,.88.49,1.85,1.85,0,0,1,.38,1.17,2,2,0,0,1-.38,1.2,1.09,1.09,0,0,1-.88.53Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M639.55,63.83a5.8,5.8,0,0,1-3.46-1,3.93,3.93,0,0,1-1.4-3.42V54.08a3.91,3.91,0,0,1,1.4-3.42,5.8,5.8,0,0,1,3.46-1,6.76,6.76,0,0,1,2.5.43,3.66,3.66,0,0,1,1.74,1.41,4.52,4.52,0,0,1,.65,2.59v5.33a4.54,4.54,0,0,1-.65,2.59,3.64,3.64,0,0,1-1.74,1.4A6.76,6.76,0,0,1,639.55,63.83Zm0-2.3a2.46,2.46,0,0,0,1.66-.52,2,2,0,0,0,.61-1.6V54.08a2,2,0,0,0-.61-1.6,2.52,2.52,0,0,0-1.69-.53,2.38,2.38,0,0,0-1.64.53,2,2,0,0,0-.6,1.6v5.33a2,2,0,0,0,.6,1.6A2.42,2.42,0,0,0,639.58,61.53Zm1.48,15.7a1.4,1.4,0,0,1-1.41-1.34,1,1,0,0,1,.15-.54l13.25-26.46a1,1,0,0,1,1-.64,1.67,1.67,0,0,1,1,.37,1.22,1.22,0,0,1,.48,1,1.34,1.34,0,0,1-.11.58L642.28,76.54a1,1,0,0,1-.48.51A1.59,1.59,0,0,1,641.06,77.23Zm14.4-1.16a5.8,5.8,0,0,1-3.46-1,4,4,0,0,1-1.4-3.42V66.32A3.93,3.93,0,0,1,652,62.9a5.8,5.8,0,0,1,3.46-1,6.76,6.76,0,0,1,2.5.43,3.73,3.73,0,0,1,1.75,1.41,4.6,4.6,0,0,1,.64,2.59v5.33a4.62,4.62,0,0,1-.64,2.59,3.71,3.71,0,0,1-1.75,1.4A6.76,6.76,0,0,1,655.46,76.07Zm0-2.3a2.46,2.46,0,0,0,1.66-.52,2,2,0,0,0,.61-1.6V66.32a2,2,0,0,0-.61-1.6,2.47,2.47,0,0,0-1.66-.53,2.37,2.37,0,0,0-1.63.53,2,2,0,0,0-.6,1.6v5.33a2,2,0,0,0,.6,1.6A2.36,2.36,0,0,0,655.49,73.77Z"
            transform="translate(-5.15 -8.16)"
          />
        </g>
        <g>
          <path
            className="cls-4"
            d="M697.32,317.31a2.71,2.71,0,0,1-1.08-.25,2.93,2.93,0,0,1-1-.68,1.29,1.29,0,0,1-.14-1.69L701.32,304l-6-10.58a2,2,0,0,1-.29-.94,1.29,1.29,0,0,1,.36-.9,2.39,2.39,0,0,1,.92-.61,2.92,2.92,0,0,1,1.1-.22,1.25,1.25,0,0,1,.88.31,2.36,2.36,0,0,1,.56.77l5,9.22,5-9.22a2.66,2.66,0,0,1,.62-.77,1.2,1.2,0,0,1,.82-.31,2.92,2.92,0,0,1,1.1.22,2.55,2.55,0,0,1,.94.61,1.27,1.27,0,0,1,.38.9,2,2,0,0,1-.29.94l-6,10.58,6.23,10.66a1.58,1.58,0,0,1,.21.75,1.38,1.38,0,0,1-.39.94,2.89,2.89,0,0,1-1,.68,2.58,2.58,0,0,1-1.09.25,1.08,1.08,0,0,1-.72-.27,2,2,0,0,1-.51-.59l-5.29-9.58-5.29,9.58a2.08,2.08,0,0,1-.49.59A1.07,1.07,0,0,1,697.32,317.31Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M721.37,315.55a1.89,1.89,0,0,1-1.13-.34,1,1,0,0,1-.49-.88v-4.18h-4.17a1.08,1.08,0,0,1-.89-.45,1.88,1.88,0,0,1-.34-1.14,2,2,0,0,1,.34-1.15,1.06,1.06,0,0,1,.89-.46h4.17v-4.14a1.12,1.12,0,0,1,.49-.87,1.75,1.75,0,0,1,1.13-.4,1.64,1.64,0,0,1,1.15.4,1.17,1.17,0,0,1,.44.87V307h4.14a1.18,1.18,0,0,1,.9.46,1.76,1.76,0,0,1,.39,1.15,1.68,1.68,0,0,1-.39,1.14,1.2,1.2,0,0,1-.9.45H723v4.18a1.08,1.08,0,0,1-.44.88A1.77,1.77,0,0,1,721.37,315.55Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M739.23,317.42a10.28,10.28,0,0,1-4.11-.79,6.27,6.27,0,0,1-2.91-2.56,8.86,8.86,0,0,1-1.08-4.68V298.92a8.67,8.67,0,0,1,1.1-4.68,6.32,6.32,0,0,1,3-2.56,10.64,10.64,0,0,1,4.21-.79,10.1,10.1,0,0,1,3.51.56,7,7,0,0,1,2.46,1.49,6,6,0,0,1,1.44,2.09,5.85,5.85,0,0,1,.47,2.27,1.57,1.57,0,0,1-.43,1.28,2.26,2.26,0,0,1-1.37.34,3,3,0,0,1-1.37-.31,1.15,1.15,0,0,1-.61-1.13,2.61,2.61,0,0,0-.57-1.71,3.87,3.87,0,0,0-1.53-1.1,5.55,5.55,0,0,0-2.11-.4,4.33,4.33,0,0,0-3.2,1.14,4.71,4.71,0,0,0-1.16,3.51v4.53a4.92,4.92,0,0,1,2.22-1.58,8.25,8.25,0,0,1,2.79-.47,9.77,9.77,0,0,1,3.71.67,5.46,5.46,0,0,1,2.64,2.26,8.39,8.39,0,0,1,1,4.42v.64a9,9,0,0,1-1.06,4.68,6.2,6.2,0,0,1-2.9,2.56A10.2,10.2,0,0,1,739.23,317.42Zm0-3.17a4.24,4.24,0,0,0,3.13-1.11,4.85,4.85,0,0,0,1.12-3.53V309a4.61,4.61,0,0,0-1.12-3.51,4.48,4.48,0,0,0-3.17-1,4.71,4.71,0,0,0-3.08,1,4,4,0,0,0-1.17,3.25v.94a4.77,4.77,0,0,0,1.14,3.53A4.29,4.29,0,0,0,739.23,314.25Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M754.74,305.07a5.7,5.7,0,0,1-3.45-1,3.91,3.91,0,0,1-1.41-3.42v-5.32a3.91,3.91,0,0,1,1.41-3.42,5.77,5.77,0,0,1,3.45-1,6.81,6.81,0,0,1,2.51.43,3.66,3.66,0,0,1,1.74,1.41,4.6,4.6,0,0,1,.65,2.59v5.32a4.63,4.63,0,0,1-.65,2.6,3.64,3.64,0,0,1-1.74,1.4A6.81,6.81,0,0,1,754.74,305.07Zm0-2.3a2.46,2.46,0,0,0,1.66-.52,2,2,0,0,0,.61-1.61v-5.32a2,2,0,0,0-.61-1.61,2.6,2.6,0,0,0-1.7-.52,2.41,2.41,0,0,0-1.63.52,2,2,0,0,0-.6,1.61v5.32a2,2,0,0,0,.6,1.61A2.42,2.42,0,0,0,754.78,302.77Zm1.48,15.69a1.31,1.31,0,0,1-1-.41,1.28,1.28,0,0,1-.4-.92,1,1,0,0,1,.15-.54l13.24-26.46a1.06,1.06,0,0,1,1-.65,1.64,1.64,0,0,1,1,.38,1.2,1.2,0,0,1,.49,1,1.5,1.5,0,0,1-.11.58l-13.21,26.35a1.1,1.1,0,0,1-.49.51A1.66,1.66,0,0,1,756.26,318.46Zm14.4-1.15a5.8,5.8,0,0,1-3.46-1,3.93,3.93,0,0,1-1.4-3.42v-5.33a3.91,3.91,0,0,1,1.4-3.42,5.8,5.8,0,0,1,3.46-1,6.76,6.76,0,0,1,2.5.43,3.69,3.69,0,0,1,1.74,1.4,4.57,4.57,0,0,1,.65,2.6v5.33a4.56,4.56,0,0,1-.65,2.59,3.69,3.69,0,0,1-1.74,1.4A6.76,6.76,0,0,1,770.66,317.31Zm0-2.3a2.46,2.46,0,0,0,1.66-.52,2,2,0,0,0,.61-1.6v-5.33a2,2,0,0,0-.61-1.6,2.47,2.47,0,0,0-1.66-.53,2.41,2.41,0,0,0-1.64.53,2,2,0,0,0-.59,1.6v5.33a2.05,2.05,0,0,0,.59,1.6A2.41,2.41,0,0,0,770.69,315Z"
            transform="translate(-5.15 -8.16)"
          />
        </g>
        <g>
          <path
            className="cls-4"
            d="M556.39,556.29a2.64,2.64,0,0,1-1.08-.25,2.87,2.87,0,0,1-1-.68,1.35,1.35,0,0,1-.4-.94,1.42,1.42,0,0,1,.25-.76L560.38,543l-6-10.59a1.89,1.89,0,0,1-.29-.93,1.29,1.29,0,0,1,.36-.9,2.29,2.29,0,0,1,.92-.61,2.92,2.92,0,0,1,1.1-.22,1.25,1.25,0,0,1,.88.31,2.22,2.22,0,0,1,.56.77l5,9.22,5-9.22a2.63,2.63,0,0,1,.61-.77,1.22,1.22,0,0,1,.83-.31,3,3,0,0,1,1.1.22,2.39,2.39,0,0,1,.93.61,1.27,1.27,0,0,1,.38.9,1.89,1.89,0,0,1-.29.93L565.42,543l6.23,10.65a1.56,1.56,0,0,1,.22.76,1.35,1.35,0,0,1-.4.94,3,3,0,0,1-1,.68,2.62,2.62,0,0,1-1.1.25,1.11,1.11,0,0,1-.72-.27,2.1,2.1,0,0,1-.5-.59l-5.3-9.58-5.29,9.58a2.39,2.39,0,0,1-.48.59A1.09,1.09,0,0,1,556.39,556.29Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M580.44,554.53a2,2,0,0,1-1.14-.34,1.06,1.06,0,0,1-.48-.89v-4.17h-4.18a1.06,1.06,0,0,1-.88-.45,1.82,1.82,0,0,1-.34-1.14,1.89,1.89,0,0,1,.34-1.15,1.05,1.05,0,0,1,.88-.47h4.18v-4.14a1.11,1.11,0,0,1,.48-.86,1.78,1.78,0,0,1,1.14-.4,1.66,1.66,0,0,1,1.15.4,1.14,1.14,0,0,1,.43.86v4.14h4.14a1.16,1.16,0,0,1,.9.47,1.75,1.75,0,0,1,.4,1.15,1.68,1.68,0,0,1-.4,1.14,1.16,1.16,0,0,1-.9.45H582v4.17a1.08,1.08,0,0,1-.43.89A1.85,1.85,0,0,1,580.44,554.53Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M593.29,556.18a2.69,2.69,0,0,1-1.32-.32,1,1,0,0,1-.59-.94v-20l-1.26,1.58a1.8,1.8,0,0,1-.41.27,1,1,0,0,1-.38.09,1.12,1.12,0,0,1-.94-.48,1.77,1.77,0,0,1-.36-1.07,1.63,1.63,0,0,1,.22-.82,1.92,1.92,0,0,1,.54-.65l3.74-3.6a1.37,1.37,0,0,1,.42-.29,1.31,1.31,0,0,1,.55-.11,2.19,2.19,0,0,1,1.16.33,1,1,0,0,1,.54.93v23.83a1,1,0,0,1-.6.94A2.62,2.62,0,0,1,593.29,556.18Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M607.26,556.4a10.25,10.25,0,0,1-4.11-.79,6.2,6.2,0,0,1-2.9-2.56,9.06,9.06,0,0,1-1.06-4.68V537.9a9.06,9.06,0,0,1,1.06-4.68,6.2,6.2,0,0,1,2.88-2.56,10.24,10.24,0,0,1,4.13-.79,10.37,10.37,0,0,1,4.12.79,6.13,6.13,0,0,1,2.91,2.56,9,9,0,0,1,1.07,4.68v10.47a9,9,0,0,1-1.07,4.68,6.13,6.13,0,0,1-2.89,2.56A10.36,10.36,0,0,1,607.26,556.4Zm0-3.38a4.26,4.26,0,0,0,3.15-1.14,4.79,4.79,0,0,0,1.13-3.51V537.9a4.79,4.79,0,0,0-1.13-3.51,4.26,4.26,0,0,0-3.15-1.14,4.2,4.2,0,0,0-3.14,1.14A4.83,4.83,0,0,0,603,537.9v10.47a4.83,4.83,0,0,0,1.11,3.51A4.2,4.2,0,0,0,607.26,553Zm0-8.32a1.46,1.46,0,0,1-1.11-.45,1.57,1.57,0,0,1-.44-1.13,1.42,1.42,0,0,1,.44-1.07,1.55,1.55,0,0,1,1.11-.41,1.51,1.51,0,0,1,1.1.41,1.43,1.43,0,0,1,.41,1.07,1.59,1.59,0,0,1-.41,1.13A1.42,1.42,0,0,1,607.29,544.7Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            className="cls-4"
            d="M623.2,544.05a5.7,5.7,0,0,1-3.45-1,3.91,3.91,0,0,1-1.4-3.42V534.3a3.91,3.91,0,0,1,1.4-3.42,5.7,5.7,0,0,1,3.45-1,6.81,6.81,0,0,1,2.51.43,3.69,3.69,0,0,1,1.74,1.4,4.66,4.66,0,0,1,.65,2.6v5.32a4.66,4.66,0,0,1-.65,2.6,3.69,3.69,0,0,1-1.74,1.4A6.81,6.81,0,0,1,623.2,544.05Zm0-2.3a2.52,2.52,0,0,0,1.66-.52,2,2,0,0,0,.61-1.61V534.3a2,2,0,0,0-.61-1.61,2.6,2.6,0,0,0-1.7-.52,2.41,2.41,0,0,0-1.63.52,2,2,0,0,0-.6,1.61v5.32a2,2,0,0,0,.6,1.61A2.47,2.47,0,0,0,623.24,541.75Zm1.48,15.69a1.31,1.31,0,0,1-1-.41,1.28,1.28,0,0,1-.4-.92,1,1,0,0,1,.15-.54l13.24-26.46a1.06,1.06,0,0,1,1-.65,1.64,1.64,0,0,1,1,.38,1.18,1.18,0,0,1,.49,1,1.5,1.5,0,0,1-.11.58l-13.21,26.35a1,1,0,0,1-.49.5A1.52,1.52,0,0,1,624.72,557.44Zm14.4-1.15a5.73,5.73,0,0,1-3.46-1,3.91,3.91,0,0,1-1.4-3.42v-5.32a3.91,3.91,0,0,1,1.4-3.42,5.73,5.73,0,0,1,3.46-1,6.76,6.76,0,0,1,2.5.43,3.69,3.69,0,0,1,1.74,1.4,4.57,4.57,0,0,1,.65,2.6v5.32a4.57,4.57,0,0,1-.65,2.6,3.69,3.69,0,0,1-1.74,1.4A6.76,6.76,0,0,1,639.12,556.29Zm0-2.3a2.52,2.52,0,0,0,1.66-.52,2,2,0,0,0,.61-1.61v-5.32a2,2,0,0,0-.61-1.61,2.52,2.52,0,0,0-1.66-.52,2.46,2.46,0,0,0-1.64.52,2.06,2.06,0,0,0-.59,1.61v5.32a2.06,2.06,0,0,0,.59,1.61A2.46,2.46,0,0,0,639.15,554Z"
            transform="translate(-5.15 -8.16)"
          />
        </g>
        <circle className="cls-5" cx="157.14" cy="352.07" r="157.14" />
        <g className="cls-6">
          <path
            d="M302.68,255.26a3,3,0,0,1-1.05.38,1,1,0,0,1-.82-.17l-12.55-9.57-.23-.21a1.16,1.16,0,0,1-.21-.25,1.08,1.08,0,0,1-.05-.92,3,3,0,0,1,.55-1,3,3,0,0,1,.81-.72,1.55,1.55,0,0,1,.69-.21.83.83,0,0,1,.65.22l9.44,7.67-2-11.91a1.18,1.18,0,0,1,.62-1.23,3.27,3.27,0,0,1,1-.31,3.52,3.52,0,0,1,1.21,0,1.07,1.07,0,0,1,.79.48,1,1,0,0,1,.13.29,1.06,1.06,0,0,1,.05.31l2.12,15.63a1.19,1.19,0,0,1-.29.81A2.84,2.84,0,0,1,302.68,255.26Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M327.77,240.61a9.88,9.88,0,0,1-4.19,1.39,7.28,7.28,0,0,1-3.81-.68,6.61,6.61,0,0,1-2.82-2.71l-1.72-3a6.22,6.22,0,0,1-.86-3.54,7.79,7.79,0,0,1,7.34-7.32,6.94,6.94,0,0,1,3.44.73,6.14,6.14,0,0,1,2.59,2.49,3.77,3.77,0,0,1,.68,2.1,1.87,1.87,0,0,1-.66,1.3,7.77,7.77,0,0,1-1.3.92l-6.57,3.82.42.71a3.48,3.48,0,0,0,2.57,1.82,5.27,5.27,0,0,0,3.45-.77,5.1,5.1,0,0,0,1.59-1.34c.34-.48.6-.9.81-1.26a2,2,0,0,1,.71-.79,1.12,1.12,0,0,1,.83-.15,2.09,2.09,0,0,1,.78.34,1.81,1.81,0,0,1,.51.53,1.74,1.74,0,0,1,0,1.4,6.1,6.1,0,0,1-1.24,2A10.17,10.17,0,0,1,327.77,240.61Zm-9-6.49,5.17-3a1.84,1.84,0,0,0,.81-.72,1.09,1.09,0,0,0-.21-.88,3.39,3.39,0,0,0-1.28-1.27,3.24,3.24,0,0,0-1.75-.44,3.74,3.74,0,0,0-1.84.55,4,4,0,0,0-1.38,1.29,3.6,3.6,0,0,0-.55,1.7,3.08,3.08,0,0,0,.45,1.78Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M347,229.12a2.11,2.11,0,0,1-1.34.31,1.07,1.07,0,0,1-.88-.48l-7.32-12.61a1,1,0,0,1,0-1,2.25,2.25,0,0,1,1-.95,2.14,2.14,0,0,1,1.23-.32,1,1,0,0,1,.88.51l.79,1.37a6.57,6.57,0,0,1,.2-1.78,6.17,6.17,0,0,1,.78-1.77,4.33,4.33,0,0,1,1.46-1.36l1-.58a1.33,1.33,0,0,1,1.11-.09,1.85,1.85,0,0,1,1.19,2,1.32,1.32,0,0,1-.62,1l-1,.57a3.86,3.86,0,0,0-1.48,1.61,4.86,4.86,0,0,0-.5,2.4,6,6,0,0,0,.87,2.76l3.71,6.38a1.12,1.12,0,0,1-.05,1A2.37,2.37,0,0,1,347,229.12Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M370.33,224.93a2.33,2.33,0,0,1-1.22.35c-.39,0-.75-.3-1.09-.88a2.6,2.6,0,0,1-.38-1.09.78.78,0,0,1,.44-.82,4,4,0,0,0,1.12-1,5.46,5.46,0,0,0,.83-1.73,7,7,0,0,0,.22-2.28,8.88,8.88,0,0,0-.65-2.66l-12.28-9a1,1,0,0,1-.38-.37,1.26,1.26,0,0,1-.08-1,3.21,3.21,0,0,1,.52-1.07,2.74,2.74,0,0,1,.81-.76,1.59,1.59,0,0,1,.69-.21.81.81,0,0,1,.65.22l9.79,7.84-2.48-12.08a.83.83,0,0,1,.12-.67,1.5,1.5,0,0,1,.47-.47,2.76,2.76,0,0,1,1-.29,3.43,3.43,0,0,1,1.24,0,1.24,1.24,0,0,1,.86.6.72.72,0,0,1,.13.42l2.39,14.89c.19,1.08.38,2.19.56,3.32a15.35,15.35,0,0,1,.18,3.29,6.42,6.42,0,0,1-3.48,5.43Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M407.76,193.83a2.19,2.19,0,0,1-1.37.34,1.08,1.08,0,0,1-.88-.49L393.09,172.3a1,1,0,0,1,0-1,2.43,2.43,0,0,1,1-1,2.53,2.53,0,0,1,1.34-.39,1,1,0,0,1,.93.49l12.42,21.39a1.09,1.09,0,0,1,0,1A2.21,2.21,0,0,1,407.76,193.83Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M428.7,182a6.94,6.94,0,0,1-3.65,1,7.93,7.93,0,0,1-3.68-.92,6.51,6.51,0,0,1-2.65-2.52l-1.66-2.87a6.69,6.69,0,0,1-.92-3.54,7.56,7.56,0,0,1,3.77-6.31,7.19,7.19,0,0,1,3.7-1,7.75,7.75,0,0,1,3.63.84,6.49,6.49,0,0,1,2.68,2.57l1.66,2.86a6.65,6.65,0,0,1,.87,3.54,8,8,0,0,1-1,3.68A7.3,7.3,0,0,1,428.7,182Zm-1.65-2.91a3.39,3.39,0,0,0,1.32-1.39,4.24,4.24,0,0,0,.44-1.9,3.7,3.7,0,0,0-.5-1.86l-1.66-2.86a3.56,3.56,0,0,0-1.35-1.32,4.12,4.12,0,0,0-1.85-.53,3.41,3.41,0,0,0-3.19,1.85,4,4,0,0,0-.42,1.85,3.53,3.53,0,0,0,.49,1.82l1.66,2.86a3.91,3.91,0,0,0,1.35,1.36,3.85,3.85,0,0,0,1.85.57A3.38,3.38,0,0,0,427.05,179.1Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M452.52,168.26a2.15,2.15,0,0,1-.87.3,1.1,1.1,0,0,1-.82-.27l-12.13-9.78-.28-.23a1.45,1.45,0,0,1-.18-.21,1,1,0,0,1-.06-.9,3.09,3.09,0,0,1,.54-1,3,3,0,0,1,.81-.72,1.55,1.55,0,0,1,.7-.22.8.8,0,0,1,.64.23l9.08,7.68-2.34-10.13a1,1,0,0,1,.12-.86,1.94,1.94,0,0,1,.61-.52,2.21,2.21,0,0,1,.79-.3,1,1,0,0,1,.81.32l7.61,7.16-2.17-11.77a1,1,0,0,1,.62-1.15,3.08,3.08,0,0,1,1-.36,2.72,2.72,0,0,1,1.1,0,1.15,1.15,0,0,1,.78.55,1.23,1.23,0,0,1,.11.27.68.68,0,0,1,.05.3l2.52,15.44a.9.9,0,0,1-.19.79,2.36,2.36,0,0,1-.71.61,2.6,2.6,0,0,1-.95.34,1,1,0,0,1-.75-.19l-7.4-6.44,1.93,9.49a1.06,1.06,0,0,1-.2.85A2.53,2.53,0,0,1,452.52,168.26Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M495.87,142.68a2.13,2.13,0,0,1-1.34.32,1.11,1.11,0,0,1-.88-.49l-7.32-12.61a1,1,0,0,1,0-1,2.42,2.42,0,0,1,1-1,2.18,2.18,0,0,1,1.23-.32,1,1,0,0,1,.88.51l.79,1.37a6.13,6.13,0,0,1,.2-1.77,5.8,5.8,0,0,1,.78-1.77,4.36,4.36,0,0,1,1.46-1.37l1-.57a1.26,1.26,0,0,1,1.11-.09,1.69,1.69,0,0,1,1,.79,1.73,1.73,0,0,1,.22,1.25,1.29,1.29,0,0,1-.61.94l-1,.58a3.8,3.8,0,0,0-1.48,1.61,4.74,4.74,0,0,0-.5,2.39,5.8,5.8,0,0,0,.87,2.76l3.71,6.39a1.09,1.09,0,0,1-.05,1A2.37,2.37,0,0,1,495.87,142.68Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M506,112.13a2.19,2.19,0,0,1-1.67.26,1.81,1.81,0,0,1-1.25-.86,1.78,1.78,0,0,1-.14-1.53,2.32,2.32,0,0,1,1.08-1.3,2.19,2.19,0,0,1,1.61-.27,1.82,1.82,0,0,1,1.25.9,1.88,1.88,0,0,1,.14,1.5A2.13,2.13,0,0,1,506,112.13Zm10.72,18.46a2.09,2.09,0,0,1-1.36.33,1.09,1.09,0,0,1-.89-.48l-7.32-12.61a1,1,0,0,1,0-1,2.46,2.46,0,0,1,1-1,2.56,2.56,0,0,1,1.34-.38,1,1,0,0,1,.92.48l7.32,12.61a1.09,1.09,0,0,1-.05,1A2.31,2.31,0,0,1,516.69,130.59Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M536.05,119.69a10.7,10.7,0,0,1-3,1.21,6.3,6.3,0,0,1-2.46.16,1.8,1.8,0,0,1-1.38-.76,1.7,1.7,0,0,1-.22-.7,1.94,1.94,0,0,1,.06-.83.93.93,0,0,1,.43-.58,1.62,1.62,0,0,1,1.06-.14,7.11,7.11,0,0,0,1.62,0,5.66,5.66,0,0,0,2.29-.85c1-.6,1.64-1.19,1.79-1.75a1.84,1.84,0,0,0-.12-1.47,1.78,1.78,0,0,0-1.28-.94,4.77,4.77,0,0,0-1.82.08c-.68.14-1.39.31-2.14.51a10.91,10.91,0,0,1-2.23.36,4.43,4.43,0,0,1-2.1-.4,4,4,0,0,1-1.74-1.74,5.33,5.33,0,0,1-.69-2.41,4.8,4.8,0,0,1,.7-2.7,7.23,7.23,0,0,1,2.74-2.48,11.42,11.42,0,0,1,2.39-1.06,7.1,7.1,0,0,1,2.13-.34,1.36,1.36,0,0,1,1.22.52,2.07,2.07,0,0,1,.19.7,2.63,2.63,0,0,1,0,.93,1,1,0,0,1-.5.68,2.05,2.05,0,0,1-.91.22,13.33,13.33,0,0,0-1.35.18,5.45,5.45,0,0,0-1.65.67,3.47,3.47,0,0,0-1.28,1.14,2.32,2.32,0,0,0-.39,1.16,1.83,1.83,0,0,0,.22,1,1.41,1.41,0,0,0,1.15.75,5.26,5.26,0,0,0,1.76-.19l2.13-.55a8.79,8.79,0,0,1,2.27-.3,4.9,4.9,0,0,1,2.18.53,4.69,4.69,0,0,1,1.84,1.92,4.5,4.5,0,0,1,.41,4A7.09,7.09,0,0,1,536.05,119.69Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M555.3,108.18a2.11,2.11,0,0,1-1.34.31,1.07,1.07,0,0,1-.88-.48L540.66,86.62a1,1,0,0,1,0-1,2.37,2.37,0,0,1,1-1,2.61,2.61,0,0,1,1.37-.4,1,1,0,0,1,.93.48l8,13.86,3-9.76a1.11,1.11,0,0,1,.52-.68,1.56,1.56,0,0,1,.87-.19,2.66,2.66,0,0,1,1,.24,1.44,1.44,0,0,1,.67.59,1.09,1.09,0,0,1,.15.39,1.17,1.17,0,0,1,0,.45l-1.94,5.92,9,4.2a1.27,1.27,0,0,1,.51.45,1.42,1.42,0,0,1,.15,1,3,3,0,0,1-.35,1,1.9,1.9,0,0,1-.68.72,1.13,1.13,0,0,1-1.14,0l-8.5-4.1-1.08,3.38,2.26,3.89a1.12,1.12,0,0,1-.05,1A2.37,2.37,0,0,1,555.3,108.18Z"
            transform="translate(-5.15 -8.16)"
          />
        </g>
        <g className="cls-6">
          <path
            d="M311.7,438.32a2.17,2.17,0,0,1-1.12-.85,1.08,1.08,0,0,1-.19-1l8.74-23.14a1,1,0,0,1,.8-.64,2.41,2.41,0,0,1,1.39.16,2.54,2.54,0,0,1,1.14.79,1,1,0,0,1,.21,1l-4.05,10.71a5.55,5.55,0,0,1,2.27-1.17,4.81,4.81,0,0,1,3,.24,5.44,5.44,0,0,1,2.58,2,7,7,0,0,1,1.23,3.2,7.48,7.48,0,0,1-.45,3.65l-3.16,8.36a1,1,0,0,1-.89.66,2.71,2.71,0,0,1-1.34-.16,2.58,2.58,0,0,1-1.06-.75,1,1,0,0,1-.25-1.09l3.16-8.35a3.44,3.44,0,0,0,.16-1.86,4.13,4.13,0,0,0-.73-1.78,3.21,3.21,0,0,0-1.51-1.15,3.29,3.29,0,0,0-1.71-.16,3.58,3.58,0,0,0-1.66.73,3.75,3.75,0,0,0-1.18,1.71l-3.16,8.35a1.09,1.09,0,0,1-.83.61A2.27,2.27,0,0,1,311.7,438.32Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M335.68,447.37a2.17,2.17,0,0,1-1.13-.84,1.1,1.1,0,0,1-.18-1l5.15-13.64a1,1,0,0,1,.8-.64,2.48,2.48,0,0,1,1.39.15,2.56,2.56,0,0,1,1.14.8,1,1,0,0,1,.21,1l-5.15,13.64a1.09,1.09,0,0,1-.83.61A2.21,2.21,0,0,1,335.68,447.37Zm7.54-20a2.25,2.25,0,0,1-1.27-1.13,1.81,1.81,0,0,1,1-2.59,2.3,2.3,0,0,1,1.69,0,2.19,2.19,0,0,1,1.23,1.08,1.84,1.84,0,0,1-1,2.59A2.06,2.06,0,0,1,343.22,427.4Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M351,461.84a12.37,12.37,0,0,1-3.39-1.92,6.72,6.72,0,0,1-2-2.59,3.78,3.78,0,0,1,0-2.86,3.53,3.53,0,0,1,1.62-2,5.59,5.59,0,0,1,2.34-.68,3.33,3.33,0,0,1-.81-1.23,2.26,2.26,0,0,1,.1-1.58,2.47,2.47,0,0,1,1-1.33,5.28,5.28,0,0,1,1.61-.59,5.43,5.43,0,0,1-.77-2.39,5.77,5.77,0,0,1,.38-2.6l.36-.95a5.77,5.77,0,0,1,1.86-2.57,5.67,5.67,0,0,1,2.94-1.12,7.79,7.79,0,0,1,3.55.54,8.08,8.08,0,0,1,2,1.11,6.62,6.62,0,0,1,1.44,1.51A5.58,5.58,0,0,1,365,439.3a1.7,1.7,0,0,1,1.25-.1,1.44,1.44,0,0,1,.93.81,1.3,1.3,0,0,1,.06.94.91.91,0,0,1-.33.44,1.24,1.24,0,0,1-.56.19,9.54,9.54,0,0,0-1,.16,3.44,3.44,0,0,0-1.23.55,4.36,4.36,0,0,1,.28,1.8,5.94,5.94,0,0,1-.39,1.85l-.36.95a5.59,5.59,0,0,1-1.89,2.55,5.82,5.82,0,0,1-3,1.16,7.82,7.82,0,0,1-3.57-.53,6.63,6.63,0,0,1-2.17-1.28,2.2,2.2,0,0,0-.61.22.86.86,0,0,0-.39.48,1,1,0,0,0,.41,1.16,9,9,0,0,0,1.75,1.12c.74.38,1.52.81,2.35,1.27a13.15,13.15,0,0,1,2.23,1.59,5,5,0,0,1,1.39,2.07,4,4,0,0,1-.18,2.72,4.49,4.49,0,0,1-2.07,2.51,6.12,6.12,0,0,1-3.2.7A12,12,0,0,1,351,461.84Zm.83-2.39a4.86,4.86,0,0,0,2.95.29,2.35,2.35,0,0,0,1.74-1.51,1.88,1.88,0,0,0,0-1.51,3.45,3.45,0,0,0-1.05-1.2,11.24,11.24,0,0,0-1.57-1l-1.63-.85a4.82,4.82,0,0,0-2,.36,2.21,2.21,0,0,0-1.25,1.32,2.38,2.38,0,0,0,.32,2.35A5.1,5.1,0,0,0,351.86,459.45Zm4.31-11.61a2.72,2.72,0,0,0,2.46-.15,3.93,3.93,0,0,0,1.66-2.1l.35-.94a3.81,3.81,0,0,0,.12-2.61,3.08,3.08,0,0,0-4.26-1.61,3.84,3.84,0,0,0-1.61,2l-.35.91a4.06,4.06,0,0,0-.16,2.67A2.69,2.69,0,0,0,356.17,447.84Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M372.05,461.11a2.11,2.11,0,0,1-1.12-.84,1.1,1.1,0,0,1-.19-1l8.74-23.13a1,1,0,0,1,.8-.64,2.41,2.41,0,0,1,1.39.16,2.54,2.54,0,0,1,1.14.79,1,1,0,0,1,.21,1l-4,10.71a5.54,5.54,0,0,1,2.27-1.16,4.88,4.88,0,0,1,3,.23,5.52,5.52,0,0,1,2.58,2,7,7,0,0,1,1.23,3.19,7.4,7.4,0,0,1-.46,3.66l-3.15,8.35a1,1,0,0,1-.89.67,2.72,2.72,0,0,1-2.41-.91,1,1,0,0,1-.24-1.09l3.16-8.35a3.57,3.57,0,0,0,.16-1.87,4.23,4.23,0,0,0-.73-1.78,3.34,3.34,0,0,0-3.22-1.31,3.6,3.6,0,0,0-1.66.74,3.84,3.84,0,0,0-1.19,1.71l-3.15,8.35a1.09,1.09,0,0,1-.83.61A2.21,2.21,0,0,1,372.05,461.11Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M409.46,475.25a2.15,2.15,0,0,1-1.09-.84,1.1,1.1,0,0,1-.18-1l5.15-13.64a1,1,0,0,1,.8-.64,2.32,2.32,0,0,1,1.35.15,2.18,2.18,0,0,1,1,.75,1,1,0,0,1,.16,1l-.56,1.48a6,6,0,0,1,1.5-1,5.82,5.82,0,0,1,1.87-.51,4.33,4.33,0,0,1,2,.27l1.08.41a1.27,1.27,0,0,1,.77.81,1.66,1.66,0,0,1,0,1.24,1.73,1.73,0,0,1-.82,1,1.29,1.29,0,0,1-1.12.12l-1.08-.41a3.81,3.81,0,0,0-2.18-.13,4.9,4.9,0,0,0-2.18,1.12,5.77,5.77,0,0,0-1.58,2.42l-2.61,6.91a1.11,1.11,0,0,1-.83.61A2.43,2.43,0,0,1,409.46,475.25Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M428.29,482.36a2.12,2.12,0,0,1-1.12-.85,1.08,1.08,0,0,1-.19-1l5.16-13.64a1,1,0,0,1,.79-.64,2.41,2.41,0,0,1,1.39.16,2.54,2.54,0,0,1,1.14.79,1,1,0,0,1,.21,1l-5.15,13.64a1.11,1.11,0,0,1-.83.61A2.28,2.28,0,0,1,428.29,482.36Zm7.54-20a2.23,2.23,0,0,1-1.26-1.13,1.89,1.89,0,0,1-.13-1.51,1.84,1.84,0,0,1,1.11-1.09,2.27,2.27,0,0,1,1.68,0,2.19,2.19,0,0,1,1.23,1.08,1.79,1.79,0,0,1,.09,1.53,1.89,1.89,0,0,1-1.07,1.06A2.14,2.14,0,0,1,435.83,462.39Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M445.26,489.08a10.82,10.82,0,0,1-2.81-1.52,6.46,6.46,0,0,1-1.68-1.81,1.79,1.79,0,0,1-.28-1.55,1.91,1.91,0,0,1,.4-.62,1.85,1.85,0,0,1,.68-.47,1,1,0,0,1,.73,0,1.65,1.65,0,0,1,.78.74,7,7,0,0,0,1,1.26,5.48,5.48,0,0,0,2.11,1.24,3.54,3.54,0,0,0,2.49.29,1.81,1.81,0,0,0,1.06-1,1.83,1.83,0,0,0-.07-1.59,5.08,5.08,0,0,0-1.22-1.36c-.54-.44-1.12-.88-1.75-1.34a10.52,10.52,0,0,1-1.68-1.5,4.37,4.37,0,0,1-1-1.89,4,4,0,0,1,.25-2.44,5.34,5.34,0,0,1,1.43-2.06,4.83,4.83,0,0,1,2.53-1.16,7.26,7.26,0,0,1,3.66.56,11.22,11.22,0,0,1,2.32,1.18,6.88,6.88,0,0,1,1.62,1.44,1.31,1.31,0,0,1,.36,1.27,2,2,0,0,1-.41.6,2.83,2.83,0,0,1-.74.56,1,1,0,0,1-.84,0,2,2,0,0,1-.75-.57,10.17,10.17,0,0,0-1-.93,5.18,5.18,0,0,0-1.56-.86,3.43,3.43,0,0,0-1.69-.27,2.39,2.39,0,0,0-1.15.43,1.75,1.75,0,0,0-.6.77,1.44,1.44,0,0,0,.14,1.37,5.37,5.37,0,0,0,1.27,1.24l1.77,1.31a8.25,8.25,0,0,1,1.66,1.57,4.69,4.69,0,0,1,1,2,4.78,4.78,0,0,1-.33,2.65,4.49,4.49,0,0,1-2.82,2.82A7.07,7.07,0,0,1,445.26,489.08Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M462.64,495.34a2.1,2.1,0,0,1-1.09-.84,1.14,1.14,0,0,1-.19-1l8.74-23.14a1,1,0,0,1,.81-.64,2.34,2.34,0,0,1,1.35.15,2.56,2.56,0,0,1,1.17.81,1,1,0,0,1,.21,1l-5.66,15,9.48-3.8a1.07,1.07,0,0,1,.86,0,1.54,1.54,0,0,1,.69.56,2.39,2.39,0,0,1,.42.88,1.52,1.52,0,0,1,0,.9,1.13,1.13,0,0,1-.22.36,1,1,0,0,1-.36.26L473,488.06l2.41,9.6a1.29,1.29,0,0,1,0,.69,1.45,1.45,0,0,1-.63.72,3.12,3.12,0,0,1-1,.38,1.93,1.93,0,0,1-1-.07,1.15,1.15,0,0,1-.75-.86l-2.19-9.18-3.3,1.3-1.59,4.21a1.11,1.11,0,0,1-.83.61A2.39,2.39,0,0,1,462.64,495.34Z"
            transform="translate(-5.15 -8.16)"
          />
        </g>
        <g className="cls-6">
          <path
            d="M327.16,341.54a2.22,2.22,0,0,1-1.39-.25,1.11,1.11,0,0,1-.61-.8L323.57,326a1,1,0,0,1,.42-.93,2.47,2.47,0,0,1,1.31-.49,2,2,0,0,1,1.21.21,1,1,0,0,1,.6.83l.14,1.28a6.26,6.26,0,0,1,1.59-2.07,5,5,0,0,1,2.87-1.17,4.4,4.4,0,0,1,3,.69,6.15,6.15,0,0,1,2.13,2.38,6.37,6.37,0,0,1,2.1-3,5.89,5.89,0,0,1,2.91-1.21,6.07,6.07,0,0,1,3.11.49,6.42,6.42,0,0,1,2.57,2.15,7.75,7.75,0,0,1,1.32,3.73l1,8.87a1.1,1.1,0,0,1-.46.92,2.24,2.24,0,0,1-1.3.55,2.2,2.2,0,0,1-1.35-.26,1.09,1.09,0,0,1-.65-.79l-1-8.88a4.11,4.11,0,0,0-.71-2,3.19,3.19,0,0,0-1.39-1.13,3.16,3.16,0,0,0-1.64-.26,3.12,3.12,0,0,0-1.56.62,3.85,3.85,0,0,0-1.12,1.39,3.77,3.77,0,0,0-.28,2.05l1,9a1,1,0,0,1-.49,1,2.91,2.91,0,0,1-1.28.43,2.81,2.81,0,0,1-1.28-.15,1,1,0,0,1-.71-.86l-1-8.95a4,4,0,0,0-.69-1.91,3.34,3.34,0,0,0-1.35-1.14,3.29,3.29,0,0,0-1.7-.27,3.25,3.25,0,0,0-1.54.61,3.37,3.37,0,0,0-1.12,1.39,4,4,0,0,0-.26,2.09l1,8.88a1.09,1.09,0,0,1-.46.91A2.26,2.26,0,0,1,327.16,341.54Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M369.74,337.15a7.07,7.07,0,0,1-3.73-.6,8.09,8.09,0,0,1-3-2.34,6.49,6.49,0,0,1-1.4-3.38l-.36-3.29a6.59,6.59,0,0,1,.6-3.61,7.23,7.23,0,0,1,2.4-2.87,7.58,7.58,0,0,1,7.39-.82,7.93,7.93,0,0,1,3,2.25,6.41,6.41,0,0,1,1.41,3.43l.36,3.29a6.59,6.59,0,0,1-.64,3.59,7.85,7.85,0,0,1-2.43,2.94A7.27,7.27,0,0,1,369.74,337.15Zm-.33-3.33a3.31,3.31,0,0,0,1.76-.74,4.12,4.12,0,0,0,1.17-1.56,3.69,3.69,0,0,0,.3-1.9l-.36-3.29a3.44,3.44,0,0,0-.7-1.75,4.16,4.16,0,0,0-1.47-1.23,3.3,3.3,0,0,0-1.92-.33,3.13,3.13,0,0,0-1.75.73,4.07,4.07,0,0,0-1.14,1.52,3.61,3.61,0,0,0-.28,1.86l.36,3.29a3.82,3.82,0,0,0,.68,1.79,4,4,0,0,0,1.46,1.27A3.32,3.32,0,0,0,369.41,333.82Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M395.4,334.33a5.26,5.26,0,0,1-3.23-.66,7.5,7.5,0,0,1-2.59-2.37,7.22,7.22,0,0,1-1.26-3.4l-.37-3.29a7.35,7.35,0,0,1,.49-3.66,7.25,7.25,0,0,1,2-2.79,5.65,5.65,0,0,1,3.08-1.3,4.9,4.9,0,0,1,3,.53,5.75,5.75,0,0,1,2,1.68l-1.25-11.38a1,1,0,0,1,.48-.94,2.66,2.66,0,0,1,1.32-.49,2.4,2.4,0,0,1,1.34.2,1,1,0,0,1,.62.82l2.7,24.58a1.12,1.12,0,0,1-.43.92,2.09,2.09,0,0,1-1.26.54,1.9,1.9,0,0,1-1.24-.27,1.07,1.07,0,0,1-.61-.8L400,331a4.91,4.91,0,0,1-1.68,2.16A5.69,5.69,0,0,1,395.4,334.33Zm.7-3.44a3.24,3.24,0,0,0,1.8-.78,5.05,5.05,0,0,0,1.17-1.41,2.25,2.25,0,0,0,.36-1.18L399,323.4a3.6,3.6,0,0,0-.76-1.94,3.71,3.71,0,0,0-1.5-1.12,3.5,3.5,0,0,0-1.77-.26,3.36,3.36,0,0,0-1.78.74,3.86,3.86,0,0,0-1.17,1.54,3.58,3.58,0,0,0-.29,1.84l.36,3.29a4.09,4.09,0,0,0,2.15,3.06A3.3,3.3,0,0,0,396.1,330.89Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M424.81,331.1a9.82,9.82,0,0,1-4.39-.42,7.26,7.26,0,0,1-3.21-2.16,6.55,6.55,0,0,1-1.48-3.63l-.38-3.4a6.41,6.41,0,0,1,.64-3.58,7.73,7.73,0,0,1,2.43-2.82,7.82,7.82,0,0,1,7.25-.9,6.83,6.83,0,0,1,2.85,2.06,6.13,6.13,0,0,1,1.36,3.32,3.72,3.72,0,0,1-.23,2.2,1.91,1.91,0,0,1-1.13.92A8.45,8.45,0,0,1,427,323l-7.55.83.09.82a3.49,3.49,0,0,0,1.62,2.7,5.17,5.17,0,0,0,3.46.69,4.84,4.84,0,0,0,2-.58,12.29,12.29,0,0,0,1.25-.83,2,2,0,0,1,1-.43,1.17,1.17,0,0,1,.82.2,2.05,2.05,0,0,1,.58.63,1.82,1.82,0,0,1,.25.69,1.73,1.73,0,0,1-.6,1.26,5.87,5.87,0,0,1-1.94,1.32A9.69,9.69,0,0,1,424.81,331.1Zm-5.65-9.59,5.94-.65a1.85,1.85,0,0,0,1-.33,1.08,1.08,0,0,0,.17-.89,3.32,3.32,0,0,0-.65-1.68,3.28,3.28,0,0,0-1.43-1.12,4.11,4.11,0,0,0-3.68.39,3.56,3.56,0,0,0-1.2,1.33,3.13,3.13,0,0,0-.31,1.81Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M445.25,328.57a2.11,2.11,0,0,1-1.35-.25,1.07,1.07,0,0,1-.61-.8l-1.6-14.5a1.06,1.06,0,0,1,.43-.93,2.39,2.39,0,0,1,1.27-.48,2.14,2.14,0,0,1,1.25.2,1,1,0,0,1,.6.83l.17,1.57a6.49,6.49,0,0,1,.9-1.55,6,6,0,0,1,1.43-1.3,4.23,4.23,0,0,1,1.88-.65l1.15-.13a1.34,1.34,0,0,1,1.06.37,1.72,1.72,0,0,1,.55,1.12,1.78,1.78,0,0,1-.3,1.23,1.3,1.3,0,0,1-.94.61L450,314a3.75,3.75,0,0,0-2,.88,4.8,4.8,0,0,0-1.44,2,5.93,5.93,0,0,0-.31,2.88l.8,7.34a1.06,1.06,0,0,1-.46.91A2.27,2.27,0,0,1,445.25,328.57Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M468.83,326.27a5.17,5.17,0,0,1-3.5-.79,4.41,4.41,0,0,1-1.78-3.41,4.35,4.35,0,0,1,.73-3.27,5.75,5.75,0,0,1,2.78-1.86,19.87,19.87,0,0,1,4.3-.91l1.57-.17-.07-.64a4.83,4.83,0,0,0-.53-1.77,3.46,3.46,0,0,0-1.28-1.35,3.31,3.31,0,0,0-2.15-.36,6.56,6.56,0,0,0-2,.48,4.71,4.71,0,0,0-1.08.66,1.53,1.53,0,0,1-.76.37,1.1,1.1,0,0,1-1.07-.52,2.62,2.62,0,0,1-.5-1.23,1.44,1.44,0,0,1,.72-1.25,7.34,7.34,0,0,1,2-1.06,10.66,10.66,0,0,1,2.44-.57,8,8,0,0,1,4.33.52,5.9,5.9,0,0,1,2.57,2.31,8.24,8.24,0,0,1,1.09,3.32l1,9a1.06,1.06,0,0,1-.42.93,2.19,2.19,0,0,1-1.26.52,2,2,0,0,1-1.26-.24,1.1,1.1,0,0,1-.6-.82l-.14-1.25a10.67,10.67,0,0,1-2,2.18A5.66,5.66,0,0,1,468.83,326.27Zm1-3a3.4,3.4,0,0,0,1.71-.73A4.92,4.92,0,0,0,472.9,321a2.94,2.94,0,0,0,.41-1.75l-.15-1.32-.89.09a19.17,19.17,0,0,0-2.56.45,4,4,0,0,0-1.87.93,2,2,0,0,0-.55,1.78,2.18,2.18,0,0,0,.86,1.7A2.52,2.52,0,0,0,469.87,323.26Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M497,322.88a7.07,7.07,0,0,1-4.8-.83,5.26,5.26,0,0,1-2.08-4.06l-2-17.93a1,1,0,0,1,.5-.94,2.53,2.53,0,0,1,1.23-.48,2.64,2.64,0,0,1,1.33.2,1,1,0,0,1,.7.81l.72,6.55,4.37-.48a.86.86,0,0,1,.81.34,1.83,1.83,0,0,1,.38.94,1.88,1.88,0,0,1-.16,1,.88.88,0,0,1-.73.51L493,309l.94,8.59a2.5,2.5,0,0,0,.76,1.76,2.75,2.75,0,0,0,2,.32l1.21-.13a1.55,1.55,0,0,1,1.29.35,1.7,1.7,0,0,1,.54,1.08,1.76,1.76,0,0,1-.29,1.15,1.52,1.52,0,0,1-1.18.64Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M519.21,320.74a9.82,9.82,0,0,1-4.39-.42,7.28,7.28,0,0,1-3.21-2.17,6.52,6.52,0,0,1-1.48-3.62l-.38-3.4a6.38,6.38,0,0,1,.64-3.58,7.57,7.57,0,0,1,2.43-2.82,7.46,7.46,0,0,1,3.61-1.36,7.66,7.66,0,0,1,3.64.45,6.92,6.92,0,0,1,2.85,2.06,6.13,6.13,0,0,1,1.36,3.33,3.75,3.75,0,0,1-.23,2.2,1.91,1.91,0,0,1-1.13.92,8.45,8.45,0,0,1-1.56.31l-7.55.83.09.83a3.51,3.51,0,0,0,1.62,2.7,5.29,5.29,0,0,0,3.46.69,5,5,0,0,0,2-.59,12.38,12.38,0,0,0,1.25-.82,1.92,1.92,0,0,1,1-.43,1.12,1.12,0,0,1,.82.2,1.91,1.91,0,0,1,.58.62,1.8,1.8,0,0,1,.25.7,1.73,1.73,0,0,1-.6,1.26,6.19,6.19,0,0,1-1.94,1.32A10.24,10.24,0,0,1,519.21,320.74Zm-5.65-9.59,5.94-.66a1.71,1.71,0,0,0,1-.33,1.1,1.1,0,0,0,.18-.88,3.42,3.42,0,0,0-.66-1.69,3.33,3.33,0,0,0-1.43-1.11,4.11,4.11,0,0,0-3.68.39,3.46,3.46,0,0,0-1.2,1.32,3.13,3.13,0,0,0-.31,1.81Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M556.11,316.4a2.13,2.13,0,0,1-1.35-.25,1.11,1.11,0,0,1-.61-.8l-1.59-14.5a1,1,0,0,1,.42-.93,2.24,2.24,0,0,1,1.27-.48,2.07,2.07,0,0,1,1.25.2,1,1,0,0,1,.6.82l.17,1.58a6.3,6.3,0,0,1,.9-1.55,6,6,0,0,1,1.43-1.3,4.41,4.41,0,0,1,1.89-.66l1.14-.12a1.34,1.34,0,0,1,1.06.37,1.69,1.69,0,0,1,.55,1.12,1.74,1.74,0,0,1-.3,1.22,1.31,1.31,0,0,1-.94.62l-1.15.12a3.84,3.84,0,0,0-2,.87,4.79,4.79,0,0,0-1.43,2,5.81,5.81,0,0,0-.32,2.88l.8,7.33a1.05,1.05,0,0,1-.46.92A2.27,2.27,0,0,1,556.11,316.4Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M575.93,292.75a2.24,2.24,0,0,1-1.64-.44,1.81,1.81,0,0,1-.79-1.29,1.78,1.78,0,0,1,.49-1.47,2.32,2.32,0,0,1,1.51-.74,2.19,2.19,0,0,1,1.58.4,1.84,1.84,0,0,1,.3,2.76A2.14,2.14,0,0,1,575.93,292.75ZM578.26,314a2.22,2.22,0,0,1-1.39-.25,1.11,1.11,0,0,1-.61-.8l-1.59-14.49a1,1,0,0,1,.42-.94,2.47,2.47,0,0,1,1.31-.49,2.59,2.59,0,0,1,1.38.2,1,1,0,0,1,.65.81l1.59,14.5a1.13,1.13,0,0,1-.46.92A2.32,2.32,0,0,1,578.26,314Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M598.58,312a10.8,10.8,0,0,1-3.19-.08,6.46,6.46,0,0,1-2.33-.85,1.83,1.83,0,0,1-.95-1.25,2,2,0,0,1,.08-.74,2.06,2.06,0,0,1,.39-.73,1,1,0,0,1,.63-.36,1.63,1.63,0,0,1,1,.31,7.91,7.91,0,0,0,1.47.67,5.75,5.75,0,0,0,2.44.15,3.48,3.48,0,0,0,2.35-.88,1.86,1.86,0,0,0-.3-2.77,4.87,4.87,0,0,0-1.7-.66c-.68-.15-1.4-.28-2.17-.4a10.61,10.61,0,0,1-2.18-.58,4.32,4.32,0,0,1-1.76-1.21,4,4,0,0,1-.89-2.3,5.28,5.28,0,0,1,.35-2.48,4.72,4.72,0,0,1,1.73-2.18,7.34,7.34,0,0,1,3.51-1.17,11.37,11.37,0,0,1,2.61,0,6.68,6.68,0,0,1,2.09.55c.56.26.86.58.9,1a1.91,1.91,0,0,1-.1.71,2.61,2.61,0,0,1-.4.84,1,1,0,0,1-.73.43,2.17,2.17,0,0,1-.92-.17,10.41,10.41,0,0,0-1.31-.38,5.13,5.13,0,0,0-1.78-.06,3.44,3.44,0,0,0-1.63.52,2.32,2.32,0,0,0-.82.91,1.78,1.78,0,0,0-.19,1,1.43,1.43,0,0,0,.74,1.15,5.51,5.51,0,0,0,1.69.54l2.18.36a8.75,8.75,0,0,1,2.19.64,4.84,4.84,0,0,1,1.78,1.36,4.74,4.74,0,0,1,.91,2.51,4.54,4.54,0,0,1-1.23,3.8A7.14,7.14,0,0,1,598.58,312Z"
            transform="translate(-5.15 -8.16)"
          />
          <path
            d="M619.05,309.49a2.21,2.21,0,0,1-1.35-.25,1.12,1.12,0,0,1-.61-.8l-2.7-24.59a1,1,0,0,1,.42-.93,2.38,2.38,0,0,1,1.27-.49,2.69,2.69,0,0,1,1.42.19,1,1,0,0,1,.65.82l1.75,15.92,6.72-7.69a1.09,1.09,0,0,1,.75-.41,1.52,1.52,0,0,1,.87.18,2.55,2.55,0,0,1,.78.6,1.51,1.51,0,0,1,.38.81,1.49,1.49,0,0,1,0,.42.91.91,0,0,1-.21.4L625,298.3l6.5,7.47a1.28,1.28,0,0,1,.29.62,1.48,1.48,0,0,1-.24.93,3,3,0,0,1-.75.8,1.91,1.91,0,0,1-.92.4,1.17,1.17,0,0,1-1.06-.43l-6.11-7.19-2.35,2.65.49,4.47a1.1,1.1,0,0,1-.46.92A2.37,2.37,0,0,1,619.05,309.49Z"
            transform="translate(-5.15 -8.16)"
          />
        </g>
        <g>
          <g>
            <path
              className="cls-4"
              d="M89.45,397.29a1.32,1.32,0,0,1-.85-.32,2.79,2.79,0,0,1-.63-.8l-5.29-10.29h-3.6v10a1,1,0,0,1-.59.94,2.8,2.8,0,0,1-1.32.32,2.76,2.76,0,0,1-1.31-.32,1,1,0,0,1-.59-.94V372.09a1.18,1.18,0,0,1,.34-.86,1.24,1.24,0,0,1,1-.36H83.4a12.4,12.4,0,0,1,4.14.66,6,6,0,0,1,3,2.27,7.65,7.65,0,0,1,1.12,4.41,8.63,8.63,0,0,1-.67,3.6,5.83,5.83,0,0,1-1.8,2.29,7.86,7.86,0,0,1-2.57,1.24l5,9.47a.85.85,0,0,1,.1.27.93.93,0,0,1,0,.27,1.44,1.44,0,0,1-.36.91,2.87,2.87,0,0,1-.88.74A2.29,2.29,0,0,1,89.45,397.29ZM79.08,382.82H83.4a5,5,0,0,0,3.22-1,5.15,5.15,0,0,0,0-6.63,5,5,0,0,0-3.22-1H79.08Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M102.12,397.47a9.87,9.87,0,0,1-4.32-.9,7.24,7.24,0,0,1-3-2.5,6.57,6.57,0,0,1-1.08-3.76v-3.42a6.26,6.26,0,0,1,1-3.5,7.78,7.78,0,0,1,2.71-2.54,7.88,7.88,0,0,1,7.31-.1,6.88,6.88,0,0,1,2.61,2.35,6.2,6.2,0,0,1,1,3.46,3.8,3.8,0,0,1-.47,2.16,1.88,1.88,0,0,1-1.22.79,8.07,8.07,0,0,1-1.58.15h-7.6v.83a3.5,3.5,0,0,0,1.31,2.86,5.19,5.19,0,0,0,3.37,1.06,5,5,0,0,0,2-.36,14.59,14.59,0,0,0,1.33-.68,2,2,0,0,1,1-.33,1.15,1.15,0,0,1,.79.29,2,2,0,0,1,.51.68,1.81,1.81,0,0,1,.18.72,1.75,1.75,0,0,1-.74,1.19,6.06,6.06,0,0,1-2.07,1.1A9.93,9.93,0,0,1,102.12,397.47Zm-4.57-10.15h6a1.77,1.77,0,0,0,1.06-.22,1.06,1.06,0,0,0,.27-.86,3.45,3.45,0,0,0-.47-1.75,3.22,3.22,0,0,0-1.3-1.26,3.83,3.83,0,0,0-1.87-.45,4,4,0,0,0-1.83.43,3.59,3.59,0,0,0-1.34,1.19,3.17,3.17,0,0,0-.5,1.77Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M116.74,397.47a10.71,10.71,0,0,1-3.17-.43,6.51,6.51,0,0,1-2.21-1.1,1.81,1.81,0,0,1-.81-1.35,1.85,1.85,0,0,1,.16-.72,1.89,1.89,0,0,1,.47-.68.91.91,0,0,1,.66-.29,1.66,1.66,0,0,1,1,.41,7.37,7.37,0,0,0,1.39.83,5.62,5.62,0,0,0,2.41.41,3.54,3.54,0,0,0,2.43-.61,1.83,1.83,0,0,0,.63-1.33,1.8,1.8,0,0,0-.63-1.46,4.75,4.75,0,0,0-1.62-.84c-.66-.22-1.36-.43-2.11-.63a12,12,0,0,1-2.1-.81,4.41,4.41,0,0,1-1.62-1.41,4.05,4.05,0,0,1-.63-2.37,5.27,5.27,0,0,1,.61-2.43,4.73,4.73,0,0,1,2-2,7.23,7.23,0,0,1,3.62-.78,11.36,11.36,0,0,1,2.59.29,6.93,6.93,0,0,1,2,.77c.52.33.79.68.79,1.07a2.09,2.09,0,0,1-.18.7,2.56,2.56,0,0,1-.49.79,1,1,0,0,1-.77.34,2.12,2.12,0,0,1-.9-.27,11.23,11.23,0,0,0-1.26-.52,5.33,5.33,0,0,0-1.76-.25,3.5,3.5,0,0,0-1.68.34,2.39,2.39,0,0,0-.92.81,1.77,1.77,0,0,0-.28.94,1.41,1.41,0,0,0,.61,1.22,5.32,5.32,0,0,0,1.62.72l2.12.59a8.74,8.74,0,0,1,2.11.89,4.35,4.35,0,0,1,2.25,4.14,4.5,4.5,0,0,1-1.64,3.63A7.1,7.1,0,0,1,116.74,397.47Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M133.84,397.47a9.84,9.84,0,0,1-4.32-.9,7.24,7.24,0,0,1-3-2.5,6.57,6.57,0,0,1-1.08-3.76v-3.42a6.34,6.34,0,0,1,1-3.5,7.81,7.81,0,0,1,2.72-2.54,7.88,7.88,0,0,1,7.31-.1,6.88,6.88,0,0,1,2.61,2.35,6.2,6.2,0,0,1,1,3.46,3.8,3.8,0,0,1-.47,2.16,1.88,1.88,0,0,1-1.22.79,8.25,8.25,0,0,1-1.59.15h-7.59v.83a3.47,3.47,0,0,0,1.31,2.86,5.19,5.19,0,0,0,3.37,1.06,5,5,0,0,0,2.05-.36,14.59,14.59,0,0,0,1.33-.68,2,2,0,0,1,1-.33,1.17,1.17,0,0,1,.79.29,2,2,0,0,1,.5.68,1.81,1.81,0,0,1,.18.72,1.73,1.73,0,0,1-.73,1.19A6.26,6.26,0,0,1,137,397,10,10,0,0,1,133.84,397.47Zm-4.57-10.15h6a1.75,1.75,0,0,0,1.06-.22,1.06,1.06,0,0,0,.27-.86,3.44,3.44,0,0,0-.46-1.75,3.35,3.35,0,0,0-1.3-1.26,3.86,3.86,0,0,0-1.87-.45,4,4,0,0,0-1.84.43,3.64,3.64,0,0,0-1.33,1.19,3.09,3.09,0,0,0-.5,1.77Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M145.07,397.18a2.1,2.1,0,0,1-1.31-.39,1.13,1.13,0,0,1-.53-.87V381.34a1,1,0,0,1,.53-.88,2.3,2.3,0,0,1,1.31-.34,2.09,2.09,0,0,1,1.22.34,1,1,0,0,1,.51.88v1.59a5.93,5.93,0,0,1,1.06-1.44,5.66,5.66,0,0,1,1.57-1.14,4.23,4.23,0,0,1,1.94-.45h1.15a1.31,1.31,0,0,1,1,.49,1.68,1.68,0,0,1,.43,1.17,1.78,1.78,0,0,1-.43,1.19,1.32,1.32,0,0,1-1,.5h-1.15a3.83,3.83,0,0,0-2.09.65,4.84,4.84,0,0,0-1.64,1.82,5.87,5.87,0,0,0-.63,2.82v7.38a1.11,1.11,0,0,1-.55.87A2.4,2.4,0,0,1,145.07,397.18Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M162.39,397.54a2.73,2.73,0,0,1-1.1-.2,1,1,0,0,1-.63-.55l-6-14.58a2.57,2.57,0,0,1-.09-.29,1,1,0,0,1-.05-.33,1,1,0,0,1,.41-.82,3.13,3.13,0,0,1,1-.58,3,3,0,0,1,1.06-.22,1.57,1.57,0,0,1,.7.17.78.78,0,0,1,.45.52L162.39,392l4.24-11.3a1.23,1.23,0,0,1,.43-.56,1.29,1.29,0,0,1,.72-.2,3.08,3.08,0,0,1,1,.22,3.36,3.36,0,0,1,1,.59,1.09,1.09,0,0,1,.45.81,1.15,1.15,0,0,1,0,.33,2,2,0,0,1-.1.29l-6,14.58a1.19,1.19,0,0,1-.66.55A2.67,2.67,0,0,1,162.39,397.54Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M180.28,397.47a9.81,9.81,0,0,1-4.32-.9,7.27,7.27,0,0,1-3-2.5,6.57,6.57,0,0,1-1.08-3.76v-3.42a6.34,6.34,0,0,1,1-3.5,7.72,7.72,0,0,1,2.72-2.54,7.88,7.88,0,0,1,7.31-.1,6.88,6.88,0,0,1,2.61,2.35,6.2,6.2,0,0,1,1,3.46,3.8,3.8,0,0,1-.47,2.16,1.88,1.88,0,0,1-1.23.79,8.07,8.07,0,0,1-1.58.15h-7.6v.83a3.48,3.48,0,0,0,1.32,2.86,5.16,5.16,0,0,0,3.36,1.06,5,5,0,0,0,2.06-.36c.52-.24,1-.47,1.33-.68a1.94,1.94,0,0,1,1-.33,1.17,1.17,0,0,1,.79.29,2.1,2.1,0,0,1,.5.68,1.81,1.81,0,0,1,.18.72,1.71,1.71,0,0,1-.74,1.19,6.06,6.06,0,0,1-2.07,1.1A9.86,9.86,0,0,1,180.28,397.47Zm-4.58-10.15h6a1.75,1.75,0,0,0,1.06-.22,1.06,1.06,0,0,0,.27-.86,3.36,3.36,0,0,0-.47-1.75,3.26,3.26,0,0,0-1.29-1.26,3.86,3.86,0,0,0-1.87-.45,4,4,0,0,0-1.84.43,3.56,3.56,0,0,0-1.33,1.19,3.09,3.09,0,0,0-.51,1.77Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M198.71,397.18a2.15,2.15,0,0,1-1.32-.39,1.14,1.14,0,0,1-.52-.87V381.34a1,1,0,0,1,.52-.88,2.36,2.36,0,0,1,1.32-.34,2.09,2.09,0,0,1,1.22.34,1,1,0,0,1,.51.88v1.59a5.68,5.68,0,0,1,1.06-1.44,5.61,5.61,0,0,1,1.56-1.14,4.3,4.3,0,0,1,1.95-.45h1.15a1.31,1.31,0,0,1,1,.49,1.68,1.68,0,0,1,.43,1.17,1.78,1.78,0,0,1-.43,1.19,1.32,1.32,0,0,1-1,.5H205a3.83,3.83,0,0,0-2.09.65,4.84,4.84,0,0,0-1.64,1.82,5.87,5.87,0,0,0-.63,2.82v7.38a1.1,1.1,0,0,1-.56.87A2.34,2.34,0,0,1,198.71,397.18Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M213,397.47a5.14,5.14,0,0,1-3.39-1.17,5.24,5.24,0,0,1-.32-6.75,5.87,5.87,0,0,1,3-1.55,19.59,19.59,0,0,1,4.37-.43h1.59v-.65a5,5,0,0,0-.35-1.82,3.41,3.41,0,0,0-1.11-1.47,3.37,3.37,0,0,0-2.11-.6,6.38,6.38,0,0,0-2.05.26,4.42,4.42,0,0,0-1.15.54,1.49,1.49,0,0,1-.8.28,1.1,1.1,0,0,1-1-.63,2.53,2.53,0,0,1-.36-1.27,1.42,1.42,0,0,1,.84-1.17,7.2,7.2,0,0,1,2.11-.83,10.21,10.21,0,0,1,2.48-.31,8,8,0,0,1,4.25,1,5.83,5.83,0,0,1,2.3,2.58,8,8,0,0,1,.73,3.42v9a1.08,1.08,0,0,1-.53.88,2.12,2.12,0,0,1-1.31.38,2,2,0,0,1-1.23-.38,1.09,1.09,0,0,1-.5-.88v-1.26a10.32,10.32,0,0,1-2.27,1.94A5.65,5.65,0,0,1,213,397.47Zm1.36-2.88a3.35,3.35,0,0,0,1.78-.54,5.1,5.1,0,0,0,1.48-1.37,2.86,2.86,0,0,0,.6-1.69v-1.33h-.91a19.47,19.47,0,0,0-2.59.16,4.14,4.14,0,0,0-2,.72,2,2,0,0,0-.74,1.71,2.17,2.17,0,0,0,.67,1.78A2.51,2.51,0,0,0,214.4,394.59Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M232.08,397.18a7.13,7.13,0,0,1-4.68-1.35,5.25,5.25,0,0,1-1.62-4.27v-18a1,1,0,0,1,.59-.88,2.53,2.53,0,0,1,1.28-.34,2.6,2.6,0,0,1,1.3.34,1,1,0,0,1,.61.88v6.59H234a.83.83,0,0,1,.77.43,1.72,1.72,0,0,1,.28,1,1.7,1.7,0,0,1-.28,1,.84.84,0,0,1-.77.44h-4.39v8.63a2.5,2.5,0,0,0,.56,1.84,2.79,2.79,0,0,0,2,.54h1.22a1.55,1.55,0,0,1,1.25.49,1.71,1.71,0,0,1,.41,1.13,1.76,1.76,0,0,1-.41,1.12,1.56,1.56,0,0,1-1.25.5Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M245,397.47a9.87,9.87,0,0,1-4.32-.9,7.24,7.24,0,0,1-3-2.5,6.57,6.57,0,0,1-1.08-3.76v-3.42a6.26,6.26,0,0,1,1-3.5,7.78,7.78,0,0,1,2.71-2.54,7.88,7.88,0,0,1,7.31-.1,6.88,6.88,0,0,1,2.61,2.35,6.2,6.2,0,0,1,1,3.46,3.72,3.72,0,0,1-.47,2.16,1.88,1.88,0,0,1-1.22.79,8.07,8.07,0,0,1-1.58.15h-7.6v.83a3.5,3.5,0,0,0,1.31,2.86,5.19,5.19,0,0,0,3.37,1.06,5,5,0,0,0,2-.36,13.37,13.37,0,0,0,1.33-.68,2,2,0,0,1,1-.33,1.15,1.15,0,0,1,.79.29,2,2,0,0,1,.51.68,1.81,1.81,0,0,1,.18.72,1.75,1.75,0,0,1-.74,1.19,6.06,6.06,0,0,1-2.07,1.1A9.93,9.93,0,0,1,245,397.47Zm-4.57-10.15h6a1.77,1.77,0,0,0,1.06-.22,1.06,1.06,0,0,0,.27-.86,3.45,3.45,0,0,0-.47-1.75,3.22,3.22,0,0,0-1.3-1.26,3.83,3.83,0,0,0-1.87-.45,4,4,0,0,0-1.83.43,3.59,3.59,0,0,0-1.34,1.19,3.17,3.17,0,0,0-.5,1.77Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M142.12,440.49a2.81,2.81,0,0,1-1.08-.25,2.89,2.89,0,0,1-1-.69,1.35,1.35,0,0,1-.39-.93,1.42,1.42,0,0,1,.25-.76l6.19-10.65-6-10.59a1.92,1.92,0,0,1-.29-.93,1.29,1.29,0,0,1,.36-.9,2.41,2.41,0,0,1,.92-.62,3.11,3.11,0,0,1,1.1-.21,1.29,1.29,0,0,1,.88.3,2.51,2.51,0,0,1,.56.78l5,9.21,5-9.21a2.64,2.64,0,0,1,.61-.78,1.25,1.25,0,0,1,.83-.3,3.15,3.15,0,0,1,1.1.21,2.53,2.53,0,0,1,.93.62,1.27,1.27,0,0,1,.38.9,1.92,1.92,0,0,1-.29.93l-6,10.59,6.23,10.65a1.67,1.67,0,0,1,.22.76,1.32,1.32,0,0,1-.4.93,2.87,2.87,0,0,1-.95.69,2.82,2.82,0,0,1-1.1.25,1.09,1.09,0,0,1-.72-.27,2.1,2.1,0,0,1-.5-.59l-5.3-9.58-5.29,9.58a2.23,2.23,0,0,1-.49.59A1.06,1.06,0,0,1,142.12,440.49Z"
              transform="translate(-5.15 -8.16)"
            />
            <path
              className="cls-4"
              d="M164.22,428.25a5.8,5.8,0,0,1-3.46-1,3.93,3.93,0,0,1-1.4-3.42v-5.33a4,4,0,0,1,1.4-3.42,5.8,5.8,0,0,1,3.46-1,6.72,6.72,0,0,1,2.5.43,3.62,3.62,0,0,1,1.75,1.4,4.62,4.62,0,0,1,.65,2.59v5.33a4.6,4.6,0,0,1-.65,2.59,3.63,3.63,0,0,1-1.75,1.41A6.72,6.72,0,0,1,164.22,428.25Zm0-2.3a2.44,2.44,0,0,0,1.65-.53,2,2,0,0,0,.61-1.6v-5.33a2,2,0,0,0-.61-1.6,2.49,2.49,0,0,0-1.69-.52,2.39,2.39,0,0,0-1.64.52,2,2,0,0,0-.59,1.6v5.33a2,2,0,0,0,.59,1.6A2.47,2.47,0,0,0,164.26,426Zm1.47,15.69a1.36,1.36,0,0,1-1-.41,1.33,1.33,0,0,1-.39-.92,1.14,1.14,0,0,1,.14-.54l13.25-26.46a1,1,0,0,1,1-.65,1.64,1.64,0,0,1,1,.38,1.18,1.18,0,0,1,.49,1,1.5,1.5,0,0,1-.11.58L167,441a1.08,1.08,0,0,1-.49.5A1.56,1.56,0,0,1,165.73,441.64Zm14.4-1.15a5.75,5.75,0,0,1-3.45-1,3.91,3.91,0,0,1-1.41-3.42v-5.33a3.94,3.94,0,0,1,1.41-3.42,5.75,5.75,0,0,1,3.45-1,6.81,6.81,0,0,1,2.51.43,3.64,3.64,0,0,1,1.74,1.4,4.62,4.62,0,0,1,.65,2.59v5.33a4.6,4.6,0,0,1-.65,2.59,3.66,3.66,0,0,1-1.74,1.41A6.81,6.81,0,0,1,180.13,440.49Zm0-2.3a2.49,2.49,0,0,0,1.66-.53,2,2,0,0,0,.61-1.6v-5.33a2,2,0,0,0-.61-1.6,2.48,2.48,0,0,0-1.66-.52,2.39,2.39,0,0,0-1.64.52,2,2,0,0,0-.59,1.6v5.33a2,2,0,0,0,.59,1.6A2.39,2.39,0,0,0,180.17,438.19Z"
              transform="translate(-5.15 -8.16)"
            />
          </g>
          <path
            className="cls-4"
            d="M162.65,289.48v-18H117.78V352.3h89.75V289.48Zm-26.92,53.85h-9v-9h9Zm0-17.95h-9v-9h9Zm0-17.95h-9v-9h9Zm0-17.95h-9v-9h9Zm18,53.85h-9v-9h9Zm0-17.95h-9v-9h9Zm0-17.95h-9v-9h9Zm0-17.95h-9v-9h9Zm44.87,53.85h-35.9v-9h9v-9h-9v-9h9v-9h-9v-9h35.9Zm-9-35.9h-9v9h9Zm0,17.95h-9v9h9Z"
            transform="translate(-5.15 -8.16)"
          />
        </g>
      </svg>
    </div>
  )
}
